import EssaySwap from "../../assets/EssaySwap.png";
import hackertype from "../../assets/hackertype.png";
import sleepscore from "../../assets/sleepscore.png";
import ResumeSwap from "../../assets/resumeswap2.png";
import findecs from "../../assets/findecs.png";
import gnsscioly from "../../assets/gnsscioly.png";
import TennisConnect from "../../assets/TennisConnect.png";
import movie from "../../assets/movie.png";
import Smash from "../../assets/Smash.png";

export const projectsData = [
  {
    id: 1,
    image: hackertype,
    title: "hackertype",
    category: "web app",
    link1: "https://hackertype.dev",
    link2: "https://github.com/max-lee-dev/hackertype",
  },

  {
    id: 2,
    image: ResumeSwap,
    title: "ResumeSwap",
    category: "web app",
    link1: "https://resumeswap.org",

  },
  {
    id: 3,
    image: TennisConnect,
    title: "TennisConnect",
    category: "mobile app",
    link2: "https://github.com/max-lee-dev/tennisconnect",
  },
  {
    id: 4,
    image: sleepscore,
    title: "sleepscore",
    category: "mobile app",
    link2: "https://github.com/max-lee-dev/sleepscore",
  },
  {
    id: 5,
    image: findecs,
    title: "FindECs",
    category: "web app",
    link1: "https://findecsarchive.org",
  },
  {
    id: 6,
    image: gnsscioly,
    title: "GNS Scioly",
    category: "web app",
    link1: "https://gnsscioly.com",
    link2: "https://github.com/max-lee-dev/gns-scioly"
  },
  {
    id: 7,
    image: movie,
    title: "movie-web-scraper",
    category: "web app",
    link1: "https://movie-web-scraper.vercel.app",
    link2: "https://github.com/max-lee-dev/movie-web-scraper"
  },
  {
    id: 8,
    image: Smash,
    title: "SmashWhosBetterAt",
    category: "web app",
    link1: "https://smashwhosbetterat.com",
    link2: "https://github.com/max-lee-dev/smashwhosbetterat"

  }

];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web app",
  },
  {
    name: "mobile app",
  },
];
